<template>
<div>

  <v-card>
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  
    <v-data-table
      :headers="headers"
      :items="policies"
      :search="search"
      sort-by="date_created"
      sort-desc
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
    >

      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-spacer></v-spacer>

          <v-dialog v-model="insuredList" persistent max-width="800px">
            <v-card>
              
              <v-card-title>
                <v-col cols="12" sm="12" md="4">
                <span class="headline">Asegurados</span>
                </v-col>
                  
                <v-spacer></v-spacer>
                <div>

              <v-btn
                style="right:10px; top:10px;"
                icon
                color="error"
                fab
                absolute
                @click="insuredList = false"
              >
                <v-icon dark>fa-times</v-icon>
              </v-btn>

                </div>
              </v-card-title>

              <v-card-text>
                <v-container>

                  <v-data-table
                    :headers="headers_insureds"
                    :items="insureds"
                    sort-by="brand"
                    :loading="loading"
                    loading-text="Consultando datos... Espere por favor"
                  >
                    <template v-slot:top>
                      <v-toolbar flat color="white">
                        <v-spacer></v-spacer>

                        <v-dialog v-model="insuredDialog" max-width="700px">
                          <template v-slot:activator="{ on }">
                              <v-btn @click="filterInsureds" ref="boton2" color="indigo" dark v-on="on">Agregar Asegurados</v-btn>
                          </template>
                          
                          
                          <v-card>
                            <v-card-title>
                              <span class="headline">Asegurados del Cliente</span>
                            </v-card-title>

                            <v-card-text>
                              <v-container>

                                <v-data-table
                                  v-model="policyholder"
                                  :headers="headers_holders"
                                  :items="policyholders"
                                  sort-by="name"
                                  class="elevation-1"
                                  :loading="loading"
                                  loading-text="Consultando datos... Espere por favor"
                                  show-select
                                  item-key="id"
                                  checkbox-color="success"
                                  :hide-default-footer="true"
                                >

                                </v-data-table>

                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="grey darken-1" text @click="closeInsured">Cancelar</v-btn>
                              <v-btn color="success darken-1" text @click="saveInsured">Agregar</v-btn>
                            </v-card-actions>
                          </v-card>

                        </v-dialog>

                        <v-dialog v-model="insuredDialogDelete" max-width="450">
                        <v-card>
                          <v-card-title>
                            <span>¿Eliminar a {{editedItem.name}}?</span>
                            <v-spacer></v-spacer>
                          </v-card-title>

                          <v-card-actions>
                            <v-btn color="green darken-1" text @click="closeInsured">Cancelar</v-btn>
                            <v-btn color="red darken-1" text @click="delete3">Aceptar</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      </v-toolbar>
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                      <!-- <v-icon small color="success" class="mr-2" @click="editItemInsured(item)">fas fa-edit</v-icon> -->
                      <v-icon small color="red" @click="deleteItemInsured(item)">fas fa-trash-alt</v-icon>
                    </template>

                  </v-data-table>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>

          <!-- ADD CARS -->
          <v-dialog 
          v-model="carList" 
          max-width="800px"
          >
            <v-card>
              
              <v-card-title>
                <v-col cols="12" sm="12" md="4">
                <span class="headline">Autos</span>
                </v-col>
                  
                <v-spacer></v-spacer>
                <div>

              <v-btn
                style="right:10px; top:10px;"
                icon
                color="error"
                fab
                absolute
                @click="carList = false"
              >
                <v-icon dark>fa-times</v-icon>
              </v-btn>

                </div>
              </v-card-title>

              <v-card-text>
                <v-container>

                  <v-data-table
                    :headers="headers_cars"
                    :items="cars"
                    sort-by="brand"
                    :loading="loading"
                    loading-text="Consultando datos... Espere por favor"
                  >
                    <template v-slot:top>
                      <v-toolbar flat color="white">
                        <v-spacer></v-spacer>

                        <v-dialog v-model="carDialog" max-width="700px">
                          <template v-slot:activator="{ on }">
                              <v-btn ref="boton2" color="primary" dark v-on="on">Nuevo Registro</v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="headline">{{ formTitle2 }}</span>
                            </v-card-title>

                            <v-card-text>
                              <v-container>
                                <v-form ref="form2" v-model="valid">
                                  <v-row>
                                    <v-col cols="12" sm="12" md="4">
                                      <v-text-field
                                        v-model="editedItem2.brand"
                                        label="Marca"
                                        :rules="editedItem2.brandRules"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                      <v-text-field
                                        v-model="editedItem2.model"
                                        label="Modelo"
                                        :rules="editedItem2.modelRules"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                      <v-text-field
                                        v-model="editedItem2.type"
                                        label="Tipo"
                                        :rules="editedItem2.typeRules"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                      <v-text-field
                                        v-model="editedItem2.color"
                                        label="Color"
                                        :rules="editedItem2.colorRules"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                      <v-text-field
                                        v-model="editedItem2.engine"
                                        label="Motor"
                                        :rules="editedItem2.engineRules"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                      <v-text-field
                                        v-model="editedItem2.chassis"
                                        label="Chasis"
                                        :rules="editedItem2.chassisRules"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                      <v-text-field
                                        v-model="editedItem2.year"
                                        type="number"
                                        v-mask="'####'"
                                        label="Año"
                                        :rules="editedItem2.yearRules"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                      <v-text-field
                                        v-model="editedItem2.cil"
                                        type="number"
                                        label="Cil"
                                        :rules="editedItem2.cilRules"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4">
                                      <v-text-field
                                        v-model="editedItem2.plate"
                                        label="Placa"
                                        :rules="editedItem2.plateRules"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="closeCar">Cancelar</v-btn>
                              <v-btn color="blue darken-1" text @click="saveCar">Guardar</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>

                        <v-dialog v-model="carDialogDelete" max-width="450">
                        <v-card>
                          <v-card-title>
                            <span>¿Eliminar vehículo con placa #{{editedItem2.plate}}?</span>
                            <v-spacer></v-spacer>
                          </v-card-title>

                          <v-card-actions>
                            <v-btn color="green darken-1" text @click="closeCar">Cancelar</v-btn>
                            <v-btn color="red darken-1" text @click="delete2">Aceptar</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      </v-toolbar>
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                      <v-icon small color="success" class="mr-2" @click="editItemCar(item)">fas fa-edit</v-icon>
                      <v-icon small color="red" @click="deleteItemCar(item)">fas fa-trash-alt</v-icon>
                    </template>

                  </v-data-table>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>

        </v-toolbar>
      </template>

      <template v-slot:[`item.action`]="{ item }">

        <v-icon v-if="item.car>0" color="blue darken-3" class="mr-3" @click="car(item)">fas fa-car-burst</v-icon>
        
        <v-tooltip top color="purple" v-if="item.policyholder>0">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="pink" v-bind="attrs" v-on="on" class="mr-3" @click="insured(item)">fas fa-person-circle-plus</v-icon>
          </template>
          <span>Asegurados</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import * as moment from 'moment';

export default {
  name: "collectives",
  data: () => ({
    dialog: false,
    dependents: [],
    policies: [],
    dialogDelete: false,
    id_customer: null,
    id_policy: null,

    cars: [],
    carDialogDelete: false,
    carDialog: false,
    carList: false,

    insureds: [],
    insuredList: false,
    insuredDialog: false,
    insuredDialogDelete: false,

    policyholders: [],
    policyholder: [],

    search: "",
    loading: "",
    titulo: "Colectivas",
    menu: false,
    headers: [
      { text: "#Póliza", align: "left", value: "policy_number" },
      { text: "Nombres", align: "left", value: "name" },
      { text: "Apellidos", align: "left", value: "surname" },
      { text: "Ramo", align: "left", value: "branch" },
      // { text: "Documentos", value: "path" },
      { text: "Acciones", value: "action", sortable: false }
    ],
    headers_cars: [
      { text: "Marca", align: "left", value: "brand" },
      { text: "Modelo", align: "left", value: "model" },
      // { text: "Tipo", align: "left", value: "type" },
      { text: "Color", align: "left", value: "color" },
      { text: "Motor", align: "left", value: "engine" },
      // { text: "Chasis", align: "left", value: "chassis" },
      { text: "Año", align: "left", value: "year" },
      { text: "Cilindraje", align: "left", value: "cil" },
      { text: "Placa", align: "left", value: "plate" },
      { text: "Acciones", align: "center", value: "action", sortable: false }
    ],
    headers_insureds: [
      { text: "#Certificado", align: "left", sortable: false, value: "certificate_number" },
      { text: "Identidad", align: "left", sortable: false, value: "identity" },
      { text: "Nombre", value: "name" },
      { text: "Dependientes", value: "dependents" },
      { text: "Fecha de alta", value: "date" },
      { text: "Acciones", align: "center", value: "action", sortable: false }
    ],
    headers_holders: [
      { text: "Identidad", align: "left", sortable: false, value: "identity" },
      { text: "#Certificado", align: "left", sortable: false, value: "certificate_number" },
      { text: "Nombre", value: "name" },
    ],
    editedIndex: -1,
    editedIndex2: -1,

    valid: true,
    editedItem: {
      id: "",
    },

    defaultItem: {
      id: "",
    },

    editedItem2: {
      id: "",
      brand: "",
      model: "",
      type: "",
      color: "",
      engine: "",
      chassis: "",
      year: "",
      cil: "",
      plate: "",
      brandRules: [v => !!v || "Escribe la marca"],
      modelRules: [v => !!v || "Escribe el modelo"],
      typeRules: [v => !!v || "Escribe el tipo"],
      colorRules: [v => !!v || "Escribe el color"],
      engineRules: [v => !!v || "Escribe la serie del motor"],
      chassisRules: [v => !!v || "Escribe la serie del chassis"],
      yearRules: [v => !!v || "Escribe el año"],
      cilRules: [v => !!v || "Escribe el cilindraje"],
      plateRules: [v => !!v || "Escribe el #placa"],
    },

    defaultItem2: {
      brand: "",
      model: "",
      type: "",
      color: "",
      engine: "",
      chassis: "",
      year: "",
      cil: "",
      plate: "",
      brandRules: [v => !!v || "Escribe la marca"],
      modelRules: [v => !!v || "Escribe el modelo"],
      typeRules: [v => !!v || "Escribe el tipo"],
      colorRules: [v => !!v || "Escribe el color"],
      engineRules: [v => !!v || "Escribe la serie del motor"],
      chassisRules: [v => !!v || "Escribe la serie del chassis"],
      yearRules: [v => !!v || "Escribe el año"],
      cilRules: [v => !!v || "Escribe el cilindraje"],
      plateRules: [v => !!v || "Escribe el #placa"],
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    formTitle2() {
      return this.editedIndex2 === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    ...mapState(["db","headers_db"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions(["cerrarSesion", "validateSession"]),

    // Metodos base de datos
    async getPolicies(){
      await axios.get(this.db + 'policies_detail',
        {
          headers: this.headers_db
        }).then(response => {
          this.policies = response.data.data;
          this.loading = false;
          console.log(response.data.data);
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getPolicies(); })
        });
    },

    async getCars(){

      await axios.get(this.db + 'cars/' + this.id_policy,
        {
          headers: this.headers_db
        }).then(response => {
          this.cars = response.data.data;
          this.loading = false;
          console.log(response.data.data);
        })
        .catch((error) => {
          this.cars = [];
          this.validateSession(error).then((resp) => { if(resp) this.getCars(); })
        });
    },

    addCar(payload){
      axios.post(this.db + 'car/add', payload,
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        this.getCars();
      })
      .catch((error) => {
        this.validateSession(error).then((resp) => { if(resp) this.addCar(payload); })
      });

    },

    updateCar(payload){

      axios.post(this.db + 'car/update', payload,
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response.data.message);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        //this.getCustomers();
      })
      .catch((error) => {
        this.validateSession(error).then((resp) => { if(resp) this.updateCar(payload); })
      });

    },

    deleteCar(id){

      axios.post(this.db + 'car/delete', { id },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.cars = this.cars.filter(doc => {
        return doc.id != id
        })
      })
      .catch((error) => {
        this.validateSession(error).then((resp) => { if(resp) this.deleteCar(id); })
      });

    },

    addInsured(payload){

      axios.post(this.db + 'insured/add/' + this.id_policy, payload,
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        this.getInsureds();
      })
      .catch((error) => {
        this.validateSession(error).then((resp) => { if(resp) this.addInsured(payload); })
      });

    },

    // Policyholders || Insureds
    async getPolicyholders(){
      console.log(this.id_customer);
      this.policyholders = [];
      if (!this.id_customer > 0) {
        return;
      }
      
      await axios.get(this.db + 'policyholders/' + this.id_customer,
        {
          headers: this.headers_db
        }).then(response => {
          this.policyholders = response.data.data;
          this.loading = false;
          console.log(response.data.data);
        })
        .catch((error) => {
          this.validateSession(error).then((resp) => { if(resp) this.getPolicyholders(); })
        });
    },

    async getInsureds(){
      this.insureds = [];
      if (!this.id_policy > 0) {
        return;
      }
      
      await axios.get(this.db + 'insureds/' + this.id_policy,
        {
          headers: this.headers_db
        }).then(response => {
          this.insureds = response.data.data;
          this.loading = false;
          console.log(response.data.data);
        })
        .catch((error) => {
          this.validateSession(error).then((resp) => { if(resp) this.getInsureds(); })
        });
    },

    deleteInsured(id){

      axios.post(this.db + 'insured/delete', { id },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.insureds = this.insureds.filter(doc => {
        return doc.id != id
        })
      })
      .catch((error) => {
        this.validateSession(error).then((resp) => { if(resp) this.deleteInsured(id); })
      });

    },

    // FIN Metodos base de datos


    // Metodos Generales

    // CARS
    editItemCar(item) {
      console.log(item);
      this.editedIndex2 = this.cars.indexOf(item);
      this.carDialog = true;
      this.editedItem2 = Object.assign({}, item);
    },

    deleteItemCar(item) {
      console.log(item.id);

      this.editedItem2 = Object.assign({}, item);
      this.carDialogDelete = true;
    },

    car(item) {
      console.log(item);
      this.id_policy = item.id;
      this.getCars();
      this.carList = true;
    },

    closeCar() {
      
      this.carDialog = false;
      this.carDialogDelete = false;
      setTimeout(() => {
        this.editedItem2 = Object.assign({}, this.defaultItem2);
        this.editedIndex2 = -1;
      }, 300);
    },

    saveCar() {
      if (this.$refs.form2.validate()) {
        if (this.editedIndex2 > -1) {
          Object.assign(this.cars[this.editedIndex2], this.editedItem2);
          this.updateCar(this.editedItem2);
        } else {
          this.cars.push(this.editedItem2);
          this.editedItem2.id_policy = this.id_policy;
          this.addCar(this.editedItem2);
        }
        this.closeCar();
      }
    },

    delete2() {
      this.deleteCar(this.editedItem2.id);
      this.closeCar();
    },

    // Insureds

    filterInsureds(){
      this.insureds.forEach(element => {
        this.policyholders = this.policyholders.filter(doc => {
        return doc.identity != element.identity
        })
      });
    },

    deleteItemInsured(item) {
      console.log(item);

      this.editedItem = Object.assign({}, item);
      this.insuredDialogDelete = true;
    },

    insured(item) {
      console.log(item);
      this.id_customer = item.id_customer;
      this.id_policy = item.id;
      this.getInsureds();
      this.getPolicyholders();
      this.insuredList = true;
    },

    closeInsured() {
      
      this.insuredDialog = false;
      this.insuredDialogDelete = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    saveInsured() {
      if (!this.policyholder.length > 0) {
        console.log("Seleccione Items");
        return;
      }
      // this.editedItem.id_policy = this.id_policy;
      this.addInsured(this.policyholder);
      this.closeInsured();
      
    },

    delete3() {
      this.deleteInsured(this.editedItem.id);
      this.closeInsured();
    },

  },
  async created() {
    this.getPolicies();
  }
};
</script>